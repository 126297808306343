export default [
  {
    header: 'Daily ten members',
  },
  {
    title: 'Member',
    route: 'member',
    icon: 'UsersIcon',
  },
  {
    title: 'Debit Account',
    route: 'user-debit',
    icon: 'CreditCardIcon',
  },
  {
    title: 'Payment Verify',
    route: 'user-authpay',
    icon: 'PackageIcon',
  },
  {
    title: 'Sundy Fund',
    route: 'sundy-fund',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'Report',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'All Reports',
        route: 'all-reports',
      },
      // {
      //   title: 'Preview',
      //   route: { name: 'apps-invoice-preview', params: { id: 4987 } },
      // },
      // {
      //   title: 'Edit',
      //   route: { name: 'apps-invoice-edit', params: { id: 4987 } },
      // },
      // {
      //   title: 'Add',
      //   route: { name: 'apps-invoice-add' },
      // },
    ],
  },
  {
    header: 'Others',
  },
  {
    title: 'Food Management',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Food Sponsor',
        route: 'food-sponsor',
      },
      {
        title: 'Food Distribution',
        route: 'food-management',
      },
    ],
  },
  {
    title: 'User',
    icon: 'UserIcon',
    children: [
      {
        title: 'User List',
        route: 'apps-users-list',
      },
    ],
  },
]
