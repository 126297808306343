import dashboard from './dashboard'
import member from './member'

export default [
  {
    title: 'Dashboards',
    route: 'home',
    icon: 'HomeIcon',
  },
  ...dashboard,
  ...member,
  {
    title: 'Second Page',
    route: 'second-page',
    icon: 'FileIcon',
  },
  {
    header: 'Access Member',
  },
  {
    title: 'Access Control',
    route: 'access-control',
    icon: 'ShieldIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'Cash Pay',
    route: 'cash-reg-fee',
    icon: 'CreditCardIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'Add Food Details',
    route: 'acl-addfood',
    icon: 'UploadIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
]
